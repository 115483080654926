@charset "UTF-8";
/**
 * Cardbox
 */
.cardbox {
  position: relative;
  border-radius: 3px;
  background-color: #fff;
  color: #4F5256;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 8px; }
  @media only screen and (min-width: 480px) {
    .cardbox {
      margin-bottom: 12px; } }
  @media only screen and (min-width: 992px) {
    .cardbox {
      margin-bottom: 24px; } }
  .cardbox .cardbox-heading {
    padding: 16px;
    margin: 0; }
    .cardbox .cardbox-heading > .cardbox-title {
      margin: 0;
      font-size: 18px; }
    .cardbox .cardbox-heading > .cardbox-icon {
      float: right;
      color: rgba(255, 255, 255, 0.87);
      font-size: 20px; }
    .cardbox .cardbox-heading > small {
      color: rgba(108, 117, 125, 0.92);
      letter-spacing: .01em; }
    @media only screen and (max-width: 767px) {
      .cardbox .cardbox-heading {
        padding: 8px; } }
  .cardbox .cardbox-body {
    position: relative;
    padding: 16px; }
    @media only screen and (max-width: 767px) {
      .cardbox .cardbox-body {
        padding: 8px; } }
  .cardbox .cardbox-footer {
    padding: 16px;
    border-top: 1px solid rgba(108, 117, 125, 0.12); }
    @media only screen and (max-width: 767px) {
      .cardbox .cardbox-footer {
        padding: 8px; } }
  .cardbox .cardbox-item {
    position: relative;
    display: block;
    min-height: 120px; }
    .cardbox .cardbox-item > .cardbox-item-text {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.35);
      margin: 0;
      color: #fff;
      padding: 8px; }
      .cardbox .cardbox-item > .cardbox-item-text a {
        color: inherit; }
    .cardbox .cardbox-item > .cardbox-item-image {
      display: block;
      width: 100%;
      height: 190px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover; }
    .cardbox .cardbox-item.cardbox-media {
      min-height: 280px;
      background-repeat: repeat;
      background-position: 50% 50%;
      background-size: cover;
      background-attachment: scroll;
      background-origin: padding-box; }
      .cardbox .cardbox-item.cardbox-media .cardbox-media-quote {
        padding: 16px;
        font-size: 35px; }
        @media only screen and (min-width: 768px) {
          .cardbox .cardbox-item.cardbox-media .cardbox-media-quote {
            font-size: 45px; } }
        .cardbox .cardbox-item.cardbox-media .cardbox-media-quote > a {
          color: inherit;
          text-decoration: none; }
        .cardbox .cardbox-item.cardbox-media .cardbox-media-quote:before {
          content: '“';
          display: block;
          font-size: 2em;
          line-height: 1;
          margin-top: 0.25em; }
  .cardbox.cardbox-transparent {
    background-color: transparent;
    border: 0;
    box-shadow: 0 0 0 #000; }
  .cardbox .cardbox-offset {
    position: relative;
    padding-bottom: 36px;
    z-index: 10; }
    .cardbox .cardbox-offset > .cardbox-offset-item {
      position: absolute;
      top: -24px;
      left: 15px;
      right: 15px; }
  .cardbox .cardbox-toolbar {
    position: relative;
    width: 100%;
    min-height: 64px;
    font-size: 18px;
    line-height: 64px;
    padding-left: 22px;
    z-index: 2; }
  .cardbox .cardbox-subheader {
    padding: 16px 0 16px 16px;
    line-height: .75em;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .01em;
    color: rgba(0, 0, 0, 0.54); }
    .cardbox .cardbox-subheader + .mda-list > .mda-list-item:first-child > .mda-list-item-text {
      padding-top: 16px; }
    .cardbox .cardbox-subheader + .mda-list > .mda-list-item:first-child > .mda-list-item-img,
    .cardbox .cardbox-subheader + .mda-list > .mda-list-item:first-child > .mda-list-item-icon,
    .cardbox .cardbox-subheader + .mda-list > .mda-list-item:first-child > .mda-list-item-initial {
      margin-top: 10px; }
  .cardbox .cardbox-divider {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.12); }
    .cardbox .cardbox-divider + .cardbox-offset {
      margin-top: -10px; }
  .cardbox > .ui-datepicker,
  .cardbox > .ui-datepicker-responsive > .ui-datepicker {
    width: 100%;
    box-shadow: 0 0 0 #000;
    margin: 0; }
    .cardbox > .ui-datepicker > table,
    .cardbox > .ui-datepicker-responsive > .ui-datepicker > table {
      width: 100%; }
  .cardbox .editable-wrap {
    width: 100%; }
  .cardbox > .list-group > .list-group-item {
    border-left: 0;
    border-right: 0; }
    .cardbox > .list-group > .list-group-item:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .cardbox > .list-group > .list-group-item:last-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: 0; }
  .cardbox > .table-responsive > .table,
  .cardbox > .table {
    margin-bottom: 0; }
  .cardbox > .table-responsive {
    border: 0; }
  .cardbox > .btn {
    border-radius: 0;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: center; }
    .cardbox > .btn:last-child {
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px; }
  .cardbox.cardbox-map {
    min-height: 280px; }
    .cardbox.cardbox-map .cardbox-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border: 0;
      background-color: transparent; }
