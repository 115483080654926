/**
 * Rows - Extras
 */

@import '../Common/Variables';
@import '../Common/Mixins';

//
// Utilities to manage bootstrap rows and cols
// with more flexibility
// Use in conjunction with .row
// ---------------------------------------------

// Responsive grid gutter

@mixin row-custom($gutter) {
    margin-right: -($gutter/2);
    margin-left: -($gutter/2);
    .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12,
    .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12,
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
    .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
        padding-right: ($gutter/2);
        padding-left: ($gutter/2);
    }
}

.row {

    @include row-custom($bs-grid-gutter-sm);

    @media #{$min-mobile} {
        @include row-custom($bs-grid-gutter-md);
    }

    @media #{$min-desktop} {
        @include row-custom($bs-grid-gutter);
    }
}

// Display a row using table layout
// allows to have vertically aligned elements

.row-table {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
    margin: 0;

    > [class*="col-"] {
        display: table-cell;
        float: none;
        table-layout: fixed;
        vertical-align: middle;
    }
}

// Remove padding and collapse columns
.row-flush {
    > [class*="col-"] {
        padding-left: 0;
        padding-right: 0;
    }
}

// T-Grid - Table based css grid (ungrid.css)
.t-grid {
    .t-row { width: 100%; display: table; table-layout: fixed; }
    .t-col, .t-cell { display: table-cell; }
}
