/**
 * Generation
 */

@import '../Common/Variables';
@import '../Common/Mixins';

.header-container {
    position: relative;
    background-color: $header-bg;
    z-index: 100;
    box-shadow: $header-shadow;
    @media #{$min-desktop} {
        margin-left: $sidebar-wd;
    }

    nav {
        padding: 0;
        // min-height: $header-hg;
        // line-height: $header-hg - 1;
        // @include clearfix;

        .navbar-brand {
            > img {
                height: calc(#{$header-hg} - #{$navbar-brand-padding-y * 2});
            }
        }

        ul.navbar-nav {
            > li {
                padding-top: 0;
                padding-bottom: 0;

                > a {
                    display: flex;
                    align-items: center;
                    height: 100%;

                    @media #{$max-mobile} {
                        white-space: normal;
                    }

                    @include media-breakpoint-down(md) {
                        max-width: 100%;
                    }

                    > em {
                        font-size: 24px;
                    }
                    .icon {
                        margin-right: 0.25em;
                    }
                    .dropdown-icon {
                        margin-left: 0.25em;
                    }

                    &:hover {
                        background-color: rgba(0,0,0,.025);
                    }

                    &.active-premise {
                        padding-left: $navbar-nav-link-padding-x;
                        padding-right: $navbar-nav-link-padding-x;
                        font-size: 15px;

                        @include media-breakpoint-down(md) {
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }
                }

                .dropdown-menu {
                    .dropdown-item {
                        @media #{$max-mobile} {
                            white-space: normal;
                            line-height: 1.2;
                            padding: $navbar-nav-link-padding-x / 2 $navbar-nav-link-padding-x;
                        }
                    }
                }
            }
        }
    }
}

.layout-container {
    position: relative;
    width: 100%;
    height: 100%;

    @media #{$max-tablet} {
        .header-container {
            .navbar-brand > img {
                transition: all 0.5s ease-out 0s;
            }
            .navbar-toggler-icon {
                transition: all 0.5s ease-out 0s;
            }
        }

        &.scrolled {
            .header-container {
                .navbar-brand > img {
                    height: calc(#{$header-hg-minimized} - #{$navbar-brand-padding-y});
                }
                .navbar-toggler-icon {
                    height: 1.2em;
                    width: 1.2em;
                    // padding-top: 0;
                    // padding-bottom: 0;
                }
            }
        }
    }
}